.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.input-container {
  position: relative;
}

.icon-input {
  padding-left: 30px; /* Create space for the icon */
}

.input-left-icon {
  position: absolute;
  left: 10px; /* Adjust the icon's position as needed */
  top: 50%;
  transform: translateY(-50%);
}

.input-right-icon {
  position: absolute;
  right: 10px; /* Adjust the icon's position as needed */
  top: 50%;
  transform: translateY(-50%);
}

.list-design{
  border-bottom: 1px solid gainsboro;
  padding-top: 5px;
  padding-bottom: 5px;
}
