@import 'normalize.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  font-family: 'Montserrat-Regular', serif;
  background: whitesmoke;
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat'), url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-Medium';
  font-weight: 900;
  src: local('Montserrat'), url('../assets/fonts/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-Black';
  font-weight: 900;
  src: local('Montserrat'), url('../assets/fonts/Montserrat-Black.ttf') format('truetype');
}
